<template>
  <div>
    <a-card title="BOM管理">
      <a-row :gutter="[12, 24]">
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.search" placeholder="产品编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 300px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>

        <a-col :span="24" style="width: 130px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增BOM</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="action" slot-scope="value, item">
              <TableAction :item="item" @update="update" @destroy="destroy" @refresh="list" />
            </template>
            <template slot="expandedRowRender" slot-scope="item">
              <TableExpandPanel :items="item.bom_component_items" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <CreateFormModal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { bomDetailList } from "@/api/goods";
import { bomDetailTemplate, bomDetailImport } from "@/api/import";
import { bomDetailExport } from "@/api/export";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
    TableExpandPanel: () => import("./TableExpandPanel"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "父产品名称",
          dataIndex: "goods_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "父产品编号",
          dataIndex: "goods_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "产品分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
        {
          title: "规格",
          dataIndex: "spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "图号",
          dataIndex: "drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "来源",
          dataIndex: "source",
          customRender: (_, item) => item.goods_item.source.join(", "),
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
      importLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      bomDetailList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      bomDetailExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, "BOM列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      bomDetailTemplate()
        .then((resp) => {
          exportExcel(resp, "BOM导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        bomDetailImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((error) => {
            if (error.response.data.detail) {
              this.$message.error(error.response.data.detail);
            } else {
              alert("导入错误:" + "\n" + error.response.data.join("\n"));
            }
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
